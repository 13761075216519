import { H1 } from "../../../AbstractElements";

const SamplePage = () => {
  return (
    <div className="page-body mb-5">
      <div>
        <H1>Sample Page</H1>
      </div>
    </div>
  );
};

export default SamplePage;
